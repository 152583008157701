import {
  BaseballCapSwitch,
  ConfirmationDialog,
  DeleteIcon,
  DownIcon,
  DropdownArrow,
  SelectInput,
  TextInput,
  UpIcon,
  When,
} from '@airelogic/form-management/components';
import UpdateIcon from '@mui/icons-material/Update';
import { FormControl, Grid, Paper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { BuildingBlockVersionData, FormSchema } from '../FormBuildingInterfaces';
import { useStyles } from './BuildingBlockEdit.styles';

interface Props {
  index: number;
  innerIndex: number;
  buildingBlocksData: BuildingBlockVersionData[];
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  onRemove: (index: number) => void;
  isLastBB: boolean;
}

export const BuildingBlockEdit = ({
  index,
  innerIndex,
  buildingBlocksData,
  onMoveUp,
  onMoveDown,
  onRemove,
  isLastBB,
}: Props) => {
  const { classes, cx } = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<FormSchema>();

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    onRemove(innerIndex);
  };

  const hideConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const formTypeWatch = useWatch({
    control,
    name: 'metadata.layout',
  });

  const relevanceRuleWatch = useWatch({
    control,
    name: `pages.${index}.buildingBlocks.${innerIndex}.relevance`,
  });

  const buildingBlockKeyWatch = useWatch({
    control,
    name: `pages.${index}.buildingBlocks.${innerIndex}.key`,
  });

  const buildingBlockVersionWatch = useWatch({
    control,
    name: `pages.${index}.buildingBlocks.${innerIndex}.version`,
  });

  const prePopulationEnabledWatch = useWatch({
    control,
    name: `pages.${index}.buildingBlocks.${innerIndex}.prePopulationEnabled`,
  });

  const getVersionsByKey = useCallback(
    (key: string) => {
      return buildingBlocksData?.find((x) => x.key === key)?.versions ?? [];
    },
    [buildingBlocksData],
  );

  const [availableBlockVersions, setAvailableBlockVersions] = React.useState<number[]>(
    getVersionsByKey(buildingBlockKeyWatch),
  );

  const [previousKey, setPreviousKey] = React.useState(buildingBlockKeyWatch);
  const [keyHasChanged, setKeyHasChanged] = React.useState(false);

  useEffect(() => {
    if (buildingBlockKeyWatch !== previousKey) {
      setPreviousKey(buildingBlockKeyWatch);
      setKeyHasChanged(true);
    }
    setAvailableBlockVersions(getVersionsByKey(buildingBlockKeyWatch));
  }, [buildingBlockKeyWatch, getVersionsByKey, previousKey]);

  useEffect(() => {
    if (keyHasChanged) {
      setValue(
        `pages.${index}.buildingBlocks.${innerIndex}.version`,
        Math.max(...availableBlockVersions, 0),
      );
    }
  }, [availableBlockVersions, index, innerIndex, keyHasChanged, setValue]);

  useEffect(() => {
    if (!prePopulationEnabledWatch) {
      setValue(`pages.${index}.buildingBlocks.${innerIndex}.prePopulationRules`, {
        rule: '',
        dataOnly: false,
        useCorrelationId: false,
      });
    }
  }, [prePopulationEnabledWatch, index, innerIndex, setValue]);

  const getErrors = () => {
    return errors?.pages?.[index]?.buildingBlocks?.[innerIndex];
  };

  const hasErrors = () => {
    return errors?.pages?.[index]?.buildingBlocks?.[innerIndex] !== undefined;
  };

  const hasCollapsedErrors = () => {
    const collapsedErrors =
      getErrors()?.customRule !== undefined ||
      getErrors()?.pdfReadVisibility !== undefined ||
      getErrors()?.prePopulationRules !== undefined ||
      getErrors()?.relevance !== undefined ||
      getErrors()?.view !== undefined;
    if (collapsedErrors && !expanded) setExpanded(true);
    return collapsedErrors;
  };

  return (
    <Paper
      elevation={2}
      data-testid="block"
      className={cx(classes.paper, { [classes.blockError]: hasErrors() })}
    >
      <Grid container spacing={2}>
        <Grid item xs="auto">
          <FormControl margin="dense">
            <DropdownArrow
              expanded={expanded}
              handleChange={setExpanded}
              tooltipText="configuration options"
            />
          </FormControl>
        </Grid>
        <Grid item xs>
          <Grid container spacing={2}>
            <Grid item xs>
              <Controller
                control={control}
                name={`pages.${index}.buildingBlocks.${innerIndex}.key`}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, data) => {
                      field.onChange(data ?? '');
                    }}
                    clearOnBlur
                    size="small"
                    handleHomeEndKeys
                    id={`pages.${index}.buildingBlocks.${innerIndex}.key`}
                    options={buildingBlocksData.map((item) => item.key)}
                    renderInput={(params) => (
                      <TextInput
                        {...params}
                        label="Building block"
                        error={getErrors()?.key !== undefined}
                        helperText={getErrors()?.key?.message}
                        fullWidth={true}
                        required
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <When condition={!!buildingBlockKeyWatch}>
              <Grid item xs="auto">
                <SelectInput
                  required
                  id={`pages.${index}.buildingBlocks.${innerIndex}.version`}
                  {...register(`pages.${index}.buildingBlocks.${innerIndex}.version`, {
                    valueAsNumber: true,
                  })}
                  label="Version"
                  tooltip={
                    availableBlockVersions.length > buildingBlockVersionWatch
                      ? 'A newer version of this block is available'
                      : ''
                  }
                  adornmentIcon={<UpdateIcon />}
                >
                  {availableBlockVersions
                    .slice()
                    .reverse()
                    .map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                </SelectInput>
              </Grid>
            </When>

            <Grid item xs="auto">
              <FormControl margin="dense">
                <UpIcon
                  aria-label="move block up in order"
                  tooltipText="Move block up in order"
                  onClick={() => onMoveUp(innerIndex)}
                />
              </FormControl>
              <FormControl margin="dense">
                <DownIcon
                  aria-label="move block down in order"
                  tooltipText="Move block down in order"
                  onClick={() => onMoveDown(innerIndex)}
                />
              </FormControl>
              <FormControl margin="dense">
                <DeleteIcon
                  disabled={isLastBB}
                  aria-label="remove building block from page"
                  tooltipText="Remove building block from page"
                  onClick={() => {
                    setShowConfirmDelete(true);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <When condition={expanded || hasCollapsedErrors()}>
            <Grid container spacing={2}>
              <Grid item xs>
                <SelectInput
                  required
                  {...register(`pages.${index}.buildingBlocks.${innerIndex}.view`)}
                  label="View"
                  tooltip={"Blocks with an 'inherit' view will share the view mode of the form."}
                >
                  <option value="inherit">Inherit</option>
                  <option value="edit">Edit</option>
                  <option value="read">Read</option>
                </SelectInput>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs>
                <SelectInput
                  required
                  {...register(`pages.${index}.buildingBlocks.${innerIndex}.pdfReadVisibility`)}
                  label="Read view visibility"
                >
                  <option value="alwaysvisible">Always visible</option>
                  <option value="hidefrompdf">Hide from PDF</option>
                  <option value="hidefromreadandpdf">Hide from read and PDF</option>
                </SelectInput>
              </Grid>

              <Grid item xs>
                <SelectInput
                  required
                  {...register(`pages.${index}.buildingBlocks.${innerIndex}.relevance`)}
                  label="Relevance"
                >
                  <option value="alwaysdisplay">Always display</option>
                  <option value="customrule">Custom rule</option>
                </SelectInput>
              </Grid>
            </Grid>
            <When condition={relevanceRuleWatch === 'customrule'}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <TextInput
                    error={getErrors()?.customRule !== undefined}
                    helperText={getErrors()?.customRule?.message}
                    {...register(`pages.${index}.buildingBlocks.${innerIndex}.customRule`)}
                    required
                    label="Custom rule"
                    type="text"
                    multiline={true}
                    tooltip={'The XPath rule governing when this building block should appear.'}
                  />
                </Grid>
              </Grid>
            </When>

            <When condition={formTypeWatch === 'clinician'}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Controller
                    control={control}
                    name={`pages.${index}.buildingBlocks.${innerIndex}.prePopulationEnabled`}
                    render={({ field }) => (
                      <BaseballCapSwitch
                        label="Enable pre-population"
                        tooltipText="Pre-population hydrates the building block with existing data for the same subject on form creation."
                        {...field}
                      />
                    )}
                    rules={{
                      deps: `pages.${index}.buildingBlocks.${innerIndex}.prePopulationRules.rule`,
                    }}
                  />
                </Grid>
                <When condition={prePopulationEnabledWatch}>
                  <Grid item xs>
                    <TextInput
                      error={getErrors()?.prePopulationRules?.rule !== undefined}
                      helperText={getErrors()?.prePopulationRules?.rule?.message}
                      {...register(
                        `pages.${index}.buildingBlocks.${innerIndex}.prePopulationRules.rule`,
                      )}
                      label="Rule"
                      type="text"
                      required
                      tooltip={
                        'A numerical value in days or a string value to provide a custom date via the plugin to pre-populate data from.'
                      }
                    />
                  </Grid>
                </When>
              </Grid>

              <When condition={prePopulationEnabledWatch}>
                <Grid container spacing={2}>
                  <Grid item xs>
                    <Controller
                      control={control}
                      name={`pages.${index}.buildingBlocks.${innerIndex}.prePopulationRules.dataOnly`}
                      render={({ field }) => (
                        <BaseballCapSwitch
                          label="Data only"
                          tooltipText="This building block will be hidden when loading form in edit and read views."
                          {...field}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs>
                    <Controller
                      control={control}
                      name={`pages.${index}.buildingBlocks.${innerIndex}.prePopulationRules.useCorrelationId`}
                      render={({ field }) => (
                        <BaseballCapSwitch
                          label="Use correlation id"
                          tooltipText="When enabled, pre-population will only use data saved against the same correlation id of the newly created form"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </When>
            </When>
          </When>
        </Grid>
      </Grid>
      <ConfirmationDialog
        confirmationText={
          'Are you sure you want to remove this building block from the page? Any configuration settings you have given it will be lost.'
        }
        open={showConfirmDelete}
        handleCancel={hideConfirmation}
        handleConfirm={confirmDelete}
      ></ConfirmationDialog>
    </Paper>
  );
};

export default BuildingBlockEdit;
