import { StyledButton } from '@airelogic/form-management/components';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InlineReferenceData } from '../../../Controls/InlineReferenceData';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';
import InlineReferenceDataDialog from '../InlineReferenceData/InlineReferenceDataDialog';
import { blankChoice } from '../InlineReferenceData/Properties/Properties';
import DefaultSelectOptions from './DefaultSelectOptions';

interface Props {
  fieldIndex: number;
}

const RadioButtonAdditionalSettings = ({ fieldIndex }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { watch, setValue, getValues } = useFormContext<FormValues>();

  const multiSelectWatch = watch(`controlSettings.${fieldIndex}.additionalSettings.multiSelect`);

  /*Set "Highlight answer in PDF" of all items to false if it is a multiselect control */
  useEffect(() => {
    if (multiSelectWatch) {
      const inlineItems = getValues(`controlSettings.${fieldIndex}.additionalSettings.items`);
      inlineItems?.forEach((item: InlineReferenceData, index: number) => {
        setValue(
          `controlSettings.${fieldIndex}.additionalSettings.items.${index}.highlightAnswerInPDF`,
          false,
        );
      });
    }
  }, [multiSelectWatch, fieldIndex, getValues, setValue]);

  return (
    <>
      <PanelWithHeader title="Choices">
        <Grid container>
          <Grid item xs={12}>
            <StyledButton onClick={() => setDialogOpen(true)}>Configure</StyledButton>
          </Grid>
        </Grid>
      </PanelWithHeader>

      <PanelWithHeader title="Additional settings">
        <Grid container>
          <DefaultSelectOptions fieldIndex={fieldIndex} supportsMultiSelect={true} />
        </Grid>
      </PanelWithHeader>

      <InlineReferenceDataDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fieldIndex={fieldIndex}
        emptyItem={blankChoice}
      />
    </>
  );
};

export default RadioButtonAdditionalSettings;
