import { TextInput } from '@airelogic/form-management/components';
import { Box, Grid, List, Typography } from '@mui/material';
import { FunctionComponent, SVGProps, useState } from 'react';
import { ControlType } from '../Controls/types';
import { allFormStructures } from '../FormStructures/Index';
import ScrollableContent from '../ScrollableContent';
import PanelWithHeader from '../SettingsPane/PanelWithHeader';
import Clipboard from './Clipboard';
import { ControlListItem } from './ControlListItem';
import { useStyles } from './ControlsPane.Styles';
import { StructureListItem } from './StructureListItem';

export interface ControlGroup {
  displayName: string;
  controls: {
    displayName: string;
    type: ControlType;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  }[];
}
interface Props {
  controlGroups: ControlGroup[];
}

const ControlsPane = ({ controlGroups }: Props) => {
  const { classes } = useStyles();

  const [query, setQuery] = useState('');

  const groups = controlGroups.filter(
    (group) =>
      query === '' ||
      group.controls.some((c) => c.displayName.toLowerCase().includes(query.toLowerCase())),
  );

  return (
    <Box display="flex" flexDirection={'column'} height="100%" paddingRight={'10px'}>
      <Clipboard />
      <Grid container>
        <Grid item xs>
          <div className={classes.header}>
            <Typography variant="h6">Add control</Typography>

            <TextInput
              id="searchControls"
              type="text"
              label="Search Controls"
              value={query}
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container style={{ flex: 1 }}>
        <Grid item xs>
          <ScrollableContent>
            <PanelWithHeader title={'Form Structure'}>
              <List component={'div'} dense disablePadding role={undefined}>
                {allFormStructures.map((structure) => (
                  <StructureListItem
                    key={structure.type}
                    label={structure.displayName}
                    icon={structure.icon}
                    type={structure.type}
                  />
                ))}
              </List>
            </PanelWithHeader>
            {groups.map((group) => {
              return (
                <PanelWithHeader title={group.displayName} key={group.displayName}>
                  <List component={'div'} dense disablePadding role={undefined}>
                    {group.controls
                      .filter(
                        (control) =>
                          query === '' ||
                          control.displayName.toLowerCase().includes(query.toLowerCase()),
                      )
                      .map((control) => {
                        return (
                          <ControlListItem
                            key={control.type}
                            type={control.type}
                            label={control.displayName}
                            icon={control.icon}
                          />
                        );
                      })}
                  </List>
                </PanelWithHeader>
              );
            })}
          </ScrollableContent>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ControlsPane;
