import { StyledButton } from '@airelogic/form-management/components';
import { Box, Grid, Theme } from '@mui/material';
import { useFormState } from 'react-hook-form';
import { useHistory } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { FormValues } from './Form';
import BlockPreviewOptions from './Preview/PreviewOptions';

const BottomBar = () => {
  const { classes } = useStyles();
  const { isSubmitting, isDirty } = useFormState<FormValues>();

  const history = useHistory();
  const onCloseClick = () => {
    history.push('/formbuilding/buildingblocks');
  };

  return (
    <Grid container spacing={0} alignItems="center" className={classes.outerGrid}>
      <Grid item className={classes.surface}>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" height="100%">
          <Box display="flex" flexDirection="row" justifyContent="center" marginRight="auto">
            <StyledButton onClick={onCloseClick} disabled={isSubmitting}>
              Close
            </StyledButton>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            className={classes.previewBtnBox}
          >
            <BlockPreviewOptions isSubmitting={isSubmitting} />
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <StyledButton type="submit" disabled={isDirty === false || isSubmitting}>
              Save
            </StyledButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BottomBar;

const useStyles = makeStyles()((theme: Theme) => ({
  surface: {
    background: '#EDF0F2',
    padding: theme.spacing(2),
    width: '100%',
    marginTop: 10,
  },

  outerGrid: {
    margin: 0,
  },

  addButton: {
    color: '#3463FD',
  },

  previewBtnBox: {
    marginRight: theme.spacing(1),
  },
}));
