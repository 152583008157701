import { ExistingTranslationsNotice } from '@airelogic/form-management/components';
import { Box, Grid, Theme } from '@mui/material';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { makeStyles } from 'tss-react/mui';
import BottomBar from './BottomBar';
import { controlPaneGroups } from './Controls/ControlGroup';
import ControlsPane from './ControlsPane/ControlsPane';
import Form, { FormValues } from './Form';
import LayoutPane from './LayoutPane/LayoutPane';
import PropertiesBar from './PropertiesBar/PropertiesBar';
import { useSaveService } from './SaveService/service';
import ScrollableContent from './ScrollableContent';
import SettingsPane from './SettingsPane/SettingsPane';
import { store } from './StateManagement/store';

interface Props {
  id: string;
  settings: FormValues;
}

const BuildingBlocksCreator = ({ id, settings }: Props) => {
  const { classes } = useStyles();
  const saveService = useSaveService();

  const onSubmit = async (data: FormValues) => {
    const success = await saveService({ id, state: store.getState().layout, settings: data });

    return { success };
  };

  return (
    <Form initialValues={settings} onSubmit={onSubmit}>
      <Box height="100%">
        <PanelGroup autoSaveId="buildingBlockDesigner" direction="horizontal">
          <ControlsPane controlGroups={controlPaneGroups} />
          <Panel>
            <Box display="flex" flexDirection={'column'} height="100%">
              <Grid container>
                <PropertiesBar />
              </Grid>
              <Grid container style={{ flex: 1 }}>
                <Grid item xs={12}>
                  <ScrollableContent className={classes.mainPaneScrollableContent}>
                    <div className={classes.mainPane}>
                      <LayoutPane />
                    </div>
                  </ScrollableContent>
                </Grid>
              </Grid>
              <Grid container>
                <BottomBar />
              </Grid>
            </Box>
          </Panel>
          <PanelResizeHandle className={classes.resizeHandleStyle} />
          <Panel maxSize={50} defaultSize={25} minSize={25}>
            <ScrollableContent>
              <SettingsPane />
            </ScrollableContent>
          </Panel>
        </PanelGroup>
      </Box>
      <ExistingTranslationsNotice
        showDialog={settings.properties.hasTranslations}
        noticeText={`This building block has existing translations. Editing the controls label, hint, helper text or any other resources will cause those translations to be lost.`}
      />
    </Form>
  );
};

export default BuildingBlocksCreator;

const useStyles = makeStyles()((theme: Theme) => ({
  mainPane: {
    height: '100%',
    padding: theme.spacing(2),
  },
  mainPaneScrollableContent: {
    backgroundColor: '#EDF0F2',
  },
  button: {
    marginBottom: theme.spacing(1),
  },
  resizeHandleStyle: {
    width: '6px',
    margin: '0 4px',
    border: '2px solid #8fa9ff36',
    '&:hover': { backgroundColor: '#8fa9ff' },
  },
}));
