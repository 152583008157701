import { ConfirmationDialog, DeleteIcon, TextInput } from '@airelogic/form-management/components';
import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormContextItemSchema, FormSchema } from '../FormBuildingInterfaces';
import { useStyles } from './FormContext.styles';

interface Props {
  remove: (index: number) => void;
  index: number;
}

const FormContextRow = ({ remove, index }: Props) => {
  const { classes } = useStyles();
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
  } = useFormContext<FormSchema>();

  const getError = (attribute: keyof FormContextItemSchema) => {
    return errors?.formContext?.[index]?.[attribute];
  };

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    onDelete();
  };

  const hideConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const onDelete = () => {
    remove(index);
  };

  return (
    <Paper elevation={2} className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <TextInput
            label={'Key'}
            required
            tooltip={'A unique identifier'}
            type="text"
            error={!!getError('key')}
            helperText={getError('key')?.message ?? ''}
            {...register(`formContext.${index}.key`)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextInput
            label={'Value'}
            required
            type="text"
            error={!!getError('value')}
            helperText={getError('value')?.message ?? ''}
            {...register(`formContext.${index}.value`)}
          />
        </Grid>
        <Grid item xs={2} className={classes.deleteIcon}>
          <DeleteIcon
            disabled={false}
            aria-label="Remove pair"
            tooltipText="Remove pair"
            onClick={() => setShowConfirmDelete(true)}
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        confirmationText={`Are you sure you want to delete this key value pair?`}
        open={showConfirmDelete}
        handleCancel={hideConfirmation}
        handleConfirm={confirmDelete}
      ></ConfirmationDialog>
    </Paper>
  );
};

export default FormContextRow;
