import { ImageUpload, SingleCheckbox, When } from '@airelogic/form-management/components';
import { Grid, Typography } from '@mui/material';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { AdditionalSettings } from '../../../Controls/AnnotatedImage';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';
interface Props {
  fieldIndex: number;
}

const AnnotatedImage = ({ fieldIndex }: Props) => {
  const {
    watch,
    formState: { errors },
    control,
  } = useFormContext<FormValues>();

  const allowsUserUploadWatch = watch(
    `controlSettings.${fieldIndex}.additionalSettings.allowUserUpload`,
  )!;

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]?.additionalSettings as
      | FieldErrors<AdditionalSettings>
      | undefined;
  };

  return (
    <PanelWithHeader title="Additional Settings">
      <Grid container>
        <Grid item xs={12}>
          <SingleCheckbox
            label="Allow User Upload"
            control={control}
            name={`controlSettings.${fieldIndex}.additionalSettings.allowUserUpload`}
            rules={{ deps: `controlSettings.${fieldIndex}.additionalSettings.imageBase64` }}
          />
        </Grid>
        <Grid item xs={12}>
          <When condition={allowsUserUploadWatch}>
            <Typography>
              * User uploaded pictures will be compressed. If the image size is larger than 1920px
              or 1MB in size, then it will be resized.
            </Typography>
          </When>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={`controlSettings.${fieldIndex}.additionalSettings.imageBase64`}
            render={({ field: { onChange, value } }) => (
              <ImageUpload
                onDelete={() => onChange('')}
                value={value || ''}
                onChange={onChange}
                error={getErrors()?.imageBase64 !== undefined}
                helperText={getErrors()?.imageBase64?.message}
                required={!allowsUserUploadWatch}
                label="Image"
              />
            )}
            rules={{
              deps: `controlSettings.${fieldIndex}.additionalSettings.allowUserUpload`,
            }}
          />
        </Grid>
      </Grid>
    </PanelWithHeader>
  );
};

export default AnnotatedImage;
