import { Box, Button, Container, Divider, Grid, Paper, Theme, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import aireInnovateLogo from './AireInnovate.webp';
import f4hLogo from './F4H.webp';

const useStyles = makeStyles()((theme: Theme) => ({
  background: {
    backgroundSize: '100% 100%',
    position: 'absolute',
    inset: '0px',
    zIndex: -1,
    '@media screen and (max-width: 1079px)': {
      backgroundImage: 'url(/assets/LoginSplash-1280x720.webp)',
    },
    '@media screen and (min-width: 1080px)': {
      backgroundImage: 'url(/assets/LoginSplash-1920x1080.webp)',
    },
  },
  paper: {
    backgroundColor: '#24243B',
    color: 'white',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: '20px',
  },
  divider: {
    backgroundColor: '#9EB3BF',
    width: '100%',
  },
}));

const LoginPage = () => {
  const history = useHistory();

  const { classes: styles } = useStyles();

  const onLoginClick = () => {
    const params = new URLSearchParams(history.location.search);
    const returnUrl = params.get('returnUrl') ?? '/';
    window.location.assign(`/oidc/login?returnUrl=${encodeURI(returnUrl)}`);
  };

  return (
    <>
      <span className={styles.background} />
      <Container maxWidth="xs">
        <Box display="flex" height="100%" alignItems="center">
          <Paper className={styles.paper} variant="outlined">
            <Grid container spacing={3}>
              <Grid container item xs={12} justifyContent="center">
                <Typography component="p" variant="h5">
                  Welcome to
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <img src={f4hLogo} alt="forms4health logo" />
              </Grid>
              <Grid container item xs={12}>
                <Button fullWidth={true} variant="contained" color="primary" onClick={onLoginClick}>
                  Sign in
                </Button>
              </Grid>
              <Grid container item xs={12}>
                <Divider className={styles.divider} orientation="horizontal" light />
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <img src={aireInnovateLogo} alt="Aire Innovate logo" />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default LoginPage;
