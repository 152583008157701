import { useAppDispatch, useAppSelector } from '../../StateManagement/hooks';
import { updateCellSelection, updateHoveredCell } from '../../StateManagement/layoutSlice';
import { Cell } from '../../StateManagement/types';
import { useStyles } from './Cell.styles';
import Component from './Component';

import { Grid } from '@mui/material';

import { useMemo, useState } from 'react';
import {
  makeSelectCanIncreaseCellSpan,
  makeSelectControlForCell,
} from '../../StateManagement/Selectors/ControlSelector';
import { makeIsSelectedCell } from '../../StateManagement/Selectors/LayoutSelectionsSelector';

interface Props {
  cell: Cell;
  repeatedIterationId?: string /*Used only for repeated grids*/;
}

const CellComponent = ({ cell, repeatedIterationId }: Props) => {
  const selectComponentForCell = useMemo(makeSelectControlForCell, []);
  const selectIsSelectedCell = useMemo(makeIsSelectedCell, []);
  const selectCanIncreaseCellSpan = useMemo(makeSelectCanIncreaseCellSpan, []);
  const component = useAppSelector((state) => selectComponentForCell(state, cell));
  const isSelectedCell = useAppSelector((state) => selectIsSelectedCell(state, cell));
  const canIncreaseSpan = useAppSelector((state) => selectCanIncreaseCellSpan(state, cell));
  const [isHovered, setIsHovered] = useState(false);

  const dispatch = useAppDispatch();
  const { classes, cx } = useStyles();

  const handleOnCellClick = () => {
    dispatch(updateCellSelection(cell));
  };

  const handleCellHover = () => {
    dispatch(updateHoveredCell(cell));
    setIsHovered(true);
  };

  const handleCellUnhover = () => {
    dispatch(updateHoveredCell());
    setIsHovered(false);
  };

  return (
    <Grid
      item
      xs
      onClick={handleOnCellClick}
      onMouseEnter={handleCellHover}
      onMouseLeave={handleCellUnhover}
      data-testid={`cell-${cell.rowId}-${cell.columnId}`}
      className={cx(classes.cell, {
        [classes.cellHovered]: isHovered,
        [classes.cellActive]: isSelectedCell,
      })}
    >
      <Grid container>
        <Grid item xs>
          {component ? (
            <Component
              id={component.id}
              type={component.type}
              cellSpan={component.cellSpan}
              canIncreaseCellSpan={canIncreaseSpan}
              repeatedIterationId={repeatedIterationId}
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CellComponent;
