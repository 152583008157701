import { SelectInput, StyledButton } from '@airelogic/form-management/components';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';
import InlineReferenceDataDialog from '../InlineReferenceData/InlineReferenceDataDialog';
import { blankRichTextSelectChoice } from '../InlineReferenceData/Properties/Properties';

interface Props {
  fieldIndex: number;
}

const options = [
  {
    value: 'full-height',
    label: 'Full Height',
  },
  {
    value: 'collapsible',
    label: 'Collapsible',
  },
];

const RichTextSelectAdditionalSettings = ({ fieldIndex }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { register } = useFormContext<FormValues>();

  return (
    <>
      <PanelWithHeader title="Choices">
        <Grid container>
          <Grid item xs={12}>
            <StyledButton onClick={() => setDialogOpen(true)}>Configure</StyledButton>
          </Grid>
        </Grid>
      </PanelWithHeader>
      <PanelWithHeader title="Additional Settings">
        <SelectInput
          id="displayType"
          label="Display Type"
          {...register(`controlSettings.${fieldIndex}.additionalSettings.display`)}
        >
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </SelectInput>
      </PanelWithHeader>
      <InlineReferenceDataDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fieldIndex={fieldIndex}
        emptyItem={blankRichTextSelectChoice}
      />
    </>
  );
};

export default RichTextSelectAdditionalSettings;
