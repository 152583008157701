import { SelectInput, StyledButton, TextInput } from '@airelogic/form-management/components';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { FieldErrors, useFormContext, useFormState } from 'react-hook-form';
import { AdditionalSettings } from '../../../Controls/ImageSelect';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';
import InlineReferenceDataDialog from '../InlineReferenceData/InlineReferenceDataDialog';
import { blankSelectImageChoice } from '../InlineReferenceData/Properties/Properties';

interface Props {
  fieldIndex: number;
}

const styleOptions = [
  {
    value: 'full',
    label: 'Full',
  },
  {
    value: 'compact',
    label: 'Compact',
  },
];

const ImageSelectAdditionalSettings = ({ fieldIndex }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { register, control } = useFormContext<FormValues>();
  const { errors } = useFormState({ control });

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]
      ?.additionalSettings as FieldErrors<AdditionalSettings>;
  };

  return (
    <>
      <PanelWithHeader title="Choices">
        <Grid container>
          <Grid item xs={12}>
            <StyledButton onClick={() => setDialogOpen(true)}>Configure</StyledButton>
          </Grid>
        </Grid>
      </PanelWithHeader>
      <PanelWithHeader title="Additional settings">
        <Grid container>
          <Grid item xs={12}>
            <SelectInput
              id="displayType"
              label="Display"
              {...register(`controlSettings.${fieldIndex}.additionalSettings.display`)}
            >
              {styleOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </SelectInput>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Choice filter XPath"
              {...register(`controlSettings.${fieldIndex}.additionalSettings.choiceFilterXPath`)}
              error={getErrors()?.choiceFilterXPath !== undefined}
              helperText={getErrors()?.choiceFilterXPath?.message}
            />
          </Grid>
        </Grid>
      </PanelWithHeader>
      <InlineReferenceDataDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fieldIndex={fieldIndex}
        emptyItem={blankSelectImageChoice}
      />
    </>
  );
};

export default ImageSelectAdditionalSettings;
