import { IconButton } from '@airelogic/form-management/components';
import BuildIcon from '@mui/icons-material/Build';
import { Box, Grid, Typography } from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { FormValues } from '../Form';
import { useAppDispatch } from '../StateManagement/hooks';
import { deselectComponent } from '../StateManagement/layoutSlice';
import { useStyles } from './PropertiesBar.Styles';

const PropertiesBar = () => {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();

  const {
    formState: { errors },
    watch,
  } = useFormContext<FormValues>();

  const title = watch('properties.blockTitle');

  const handleSpannerClick = () => {
    dispatch(deselectComponent());
  };

  return (
    <Grid container className={classes.outerGrid}>
      <Grid item className={classes.surface}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex">
            <Box display="flex" flexDirection="row">
              <Typography variant="h6">{title}</Typography>
            </Box>
          </Box>
          <IconButton
            onClick={handleSpannerClick}
            tooltipText="Edit Building Block Properties"
            className={cx({
              [classes.spannerButtonError]: !!errors?.properties,
            })}
          >
            <BuildIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PropertiesBar;
