import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAppDispatch, useAppSelector } from '../../StateManagement/hooks';
import {
  addRepeatIteration,
  deleteGrid,
  moveGrid,
  updateSelectedComponent,
} from '../../StateManagement/layoutSlice';
import { RepeatingGrid } from '../../StateManagement/types';
import { useStyles } from './RepeatedGrid.styles';

import { ConfirmationDialog, IconButton, When } from '@airelogic/form-management/components';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Grid } from '@mui/material';

import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../Form';
import {
  makeIsSelectedGrid,
  makeSelectCanDeleteGrid,
  makeSelectControlCountInGrid,
} from '../../StateManagement/Selectors/GridSelector';
import { RootState } from '../../StateManagement/store';
import ColumnOptions from './ColumnOptions';
import RepeatIterationRow from './RepeatIterationRow';
import { AffectedControl, useRepeatedDefaultValues } from './useRepeatedDefaultValues';

interface Props {
  repeatingGrid: RepeatingGrid;
  settingsIndex: number;
}

const RepeatedGrid = ({ repeatingGrid, settingsIndex }: Props) => {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [repeatColumnIdHeadingHovered, setRepeatColumnIdHeadingHovered] = useState<
    string | undefined
  >();

  const selectIsSelectedRepeat = useMemo(makeIsSelectedGrid, []);

  const isSelectedRepeat = useAppSelector((state) =>
    selectIsSelectedRepeat(state, repeatingGrid.id),
  );

  const selectComponentCount = useMemo(makeSelectControlCountInGrid, []);
  const selectCanDeleteGrid = useMemo(makeSelectCanDeleteGrid, []);

  const canDeleteGrid = useAppSelector((state) => selectCanDeleteGrid(state, repeatingGrid.id));
  const controlsCount = useAppSelector((state: RootState) =>
    selectComponentCount(state, repeatingGrid.id),
  );

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<FormValues>();

  const newRepeatedControls = useRepeatedDefaultValues({
    gridIterationIds: repeatingGrid?.iterationIds,
    gridId: repeatingGrid.id,
  });

  useEffect(() => {
    newRepeatedControls.affectedControls.forEach((affectedControl: AffectedControl) => {
      setValue(
        `controlSettings.${affectedControl.index}.basicSettings.repeatableDefaultValues`,
        affectedControl.newRepeatableDefaultValues,
      );
    });
  }, [newRepeatedControls, setValue]);

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const minimumRepetitions = watch(
    `repeatSettings.${settingsIndex}.contentSettings.minimumRepetitions`,
  );
  const maximumRepetitions = watch(
    `repeatSettings.${settingsIndex}.contentSettings.maximumRepetitions`,
  );

  const hasErrors = errors.repeatSettings?.[settingsIndex] !== undefined;
  const canAddIteration = maximumRepetitions
    ? repeatingGrid.iterationIds.length < maximumRepetitions
    : true;

  const canDeleteIteration = minimumRepetitions
    ? repeatingGrid.iterationIds.length > minimumRepetitions
    : true;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleAddIteration = () => {
    if (canAddIteration) {
      dispatch(
        addRepeatIteration({
          gridId: repeatingGrid.id,
        }),
      );
    }
  };

  const onDeleteGrid = () => {
    controlsCount > 0 ? setShowConfirmDelete(true) : confirmDelete();
  };

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    dispatch(deleteGrid({ gridId: repeatingGrid.id }));
  };

  const hideConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const repeatColumnHeadingHover = (columnId: string) => {
    setRepeatColumnIdHeadingHovered(columnId);
  };

  const repeatColumnHeadingUnhover = () => {
    setRepeatColumnIdHeadingHovered(undefined);
  };

  const displaySettings = () => {
    dispatch(updateSelectedComponent({ type: 'grid', id: repeatingGrid.id }));
  };

  const handleGridMoveDown = () => {
    dispatch(moveGrid({ gridId: repeatingGrid.id, direction: 'down' }));
  };

  const handleGridMoveUp = () => {
    dispatch(moveGrid({ gridId: repeatingGrid.id, direction: 'up' }));
  };

  return (
    <div
      className={cx(classes.repeatedGrid, {
        [classes.repeatedGridActive]: isSelectedRepeat,
      })}
      data-testid={`repeated-grid-${repeatingGrid.id}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-haserror={hasErrors}
      data-componenttype="grid"
      data-componentid={repeatingGrid.id}
    >
      <Grid container className={classes.actionButtons} style={{ height: '24px' }}>
        <When condition={isHovered && canDeleteGrid}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton tooltipText="Move grid down" onClick={handleGridMoveDown}>
              <ArrowDownward />
            </IconButton>
            <IconButton tooltipText="Move grid up" onClick={handleGridMoveUp}>
              <ArrowUpward />
            </IconButton>
            <IconButton tooltipText="Delete grid" onClick={onDeleteGrid}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </When>
      </Grid>
      <Grid container>
        <Box width={'24px'}>{/* Leaving space for repeat controls */}</Box>
        <Box flex={1}>
          <Grid container>
            {repeatingGrid.columnIds.map((columnId) => {
              return (
                <ColumnOptions
                  key={columnId}
                  gridId={repeatingGrid.id}
                  columnId={columnId}
                  display={repeatColumnIdHeadingHovered === columnId}
                />
              );
            })}
          </Grid>
        </Box>
      </Grid>
      <Grid container>
        <Box width={'24px'}>
          <IconButton tooltipText="Display repeat settings" onClick={displaySettings}>
            <SettingsIcon color={hasErrors ? 'error' : 'secondary'} />
          </IconButton>
        </Box>
        <Box className={classes.border}>
          <IconButton
            onClick={handleAddIteration}
            tooltipText="Add iteration"
            disabled={!canAddIteration}
          >
            <AddCircleIcon />
          </IconButton>
        </Box>
        <Box flex={1} className={cx(classes.borderTop, classes.borderRight)}>
          <Grid container style={{ height: '100%' }}>
            {repeatingGrid.columnIds.map((columnId) => {
              return (
                <Grid
                  item
                  xs
                  key={columnId}
                  onMouseEnter={() => repeatColumnHeadingHover(columnId)}
                  onMouseLeave={repeatColumnHeadingUnhover}
                />
              );
            })}
          </Grid>
        </Box>
      </Grid>
      <Grid container>
        {repeatingGrid.iterationIds.map((iterationId) => {
          return (
            <Grid container key={iterationId} data-testid={`iteration-${iterationId}`}>
              {repeatingGrid.rowIds.map((rowId, index) => {
                return (
                  <RepeatIterationRow
                    key={`${iterationId}-${rowId}`}
                    repeatingGrid={repeatingGrid}
                    iterationId={iterationId}
                    rowId={rowId}
                    rowIndex={index}
                    canDeleteIteration={canDeleteIteration}
                    canAddIteration={canAddIteration}
                  />
                );
              })}
            </Grid>
          );
        })}
      </Grid>
      <ConfirmationDialog
        data-testid={`deleteRepeatingGridPrompt-${repeatingGrid.id}`}
        confirmationText={'Are you sure you want to delete this repeating grid?'}
        open={showConfirmDelete}
        handleCancel={hideConfirmation}
        handleConfirm={confirmDelete}
      />
    </div>
  );
};
export default RepeatedGrid;
