import { createPreviewUrl } from '@airelogic/form-management/common';
import {
  ExistingTranslationsNotice,
  NavigatePrompt,
  StyledButton,
} from '@airelogic/form-management/components';
import { zodResolver } from '@hookform/resolvers/zod';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, Container, Grid, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { buildForm, buildFormPreview } from '../FormBuilding.Service';
import { BuildingBlockVersionData, FormSchema, schema } from '../FormBuildingInterfaces';
import MetadataEdit from '../MetadataEdit/MetadataEdit';
import PageEdit from '../PageEdit/PageEdit';
import { useSaveForm, useSaveFormPreview } from '../Service';
import { FormsTab } from '../Tabs/FormsTab';
import { useStyles } from './FormEdit.styles';
import { TabPanel, a11yProps } from './TabPanel';

export type EditorMode = 'new' | 'edit' | 'newversion';

type Props = {
  blockVersions: BuildingBlockVersionData[];
  editorMode: EditorMode;
  currentValues: FormSchema;
  refreshBlocks: () => Promise<void>;
  isRefreshingBlocks: boolean;
  classifications: string[];
};

export const FormEdit = ({
  blockVersions,
  editorMode,
  currentValues,
  refreshBlocks,
  isRefreshingBlocks,
  classifications,
}: Props) => {
  const { classes } = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [headerText] = React.useState(() => {
    switch (editorMode) {
      case 'new':
        return 'Create Form';
      case 'edit':
        return 'Edit Form';
      case 'newversion':
        return 'New Version';
    }
  });

  const history = useHistory();
  const formMethods = useForm<FormSchema>({
    defaultValues: currentValues,
    resolver: zodResolver(schema),
  });
  const {
    control,
    handleSubmit,
    getValues,
    setError,
    trigger,
    reset,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = formMethods;

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'pages',
  });

  const saveForm = useSaveForm({
    editorMode,
    key: currentValues.metadata.key,
    version: currentValues.metadata.version,
  });
  const savePreviewForm = useSaveFormPreview();

  const onSubmit: SubmitHandler<FormSchema> = async (formData) => {
    const response = await saveForm(buildForm(formData));

    if (response === 409) {
      setError('metadata.key', {
        type: 'manual',
        message: 'This form key is already in use',
      });
    }

    if (response === 200) {
      reset(undefined, { keepValues: true, keepDirty: false });
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      history.push('/formbuilding/forms');
    }
  }, [history, isSubmitSuccessful]);

  const [tabsToBeDeleted, setTabsToBeDeleted] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (tabsToBeDeleted !== undefined) {
      if (tabsToBeDeleted > 0) {
        setTabValue(tabsToBeDeleted - 1);
      } else {
        setTabValue(0);
      }
      remove(tabsToBeDeleted);
      setTabsToBeDeleted(undefined);
    }
  }, [tabsToBeDeleted, remove]);

  const generatePreview = async () => {
    if (!(await trigger())) {
      return;
    }

    const formInput = getValues();

    const success = await savePreviewForm(buildFormPreview(formInput));

    if (success) {
      const previewLink = createPreviewUrl(
        formInput.metadata.layout === 'clinician' ? 'document' : 'wizard',
        'preview-form',
        1,
      );
      window.open(previewLink, '_blank');
    }
  };

  const handleChange = (_event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  const handleCreateNewPage = () => {
    append({
      title: '',
      buildingBlocks: [
        {
          key: '',
          version: 0,
          view: 'inherit',
          pdfReadVisibility: 'alwaysvisible',
          relevance: 'alwaysdisplay',
          customRule: '',
          prePopulationEnabled: false,
          prePopulationRules: {
            rule: '',
            useCorrelationId: false,
            dataOnly: false,
          },
        },
      ],
    });

    setTabValue(fields.length);
  };

  const handleRemovePage = (pageIndex: number) => {
    if (fields.length > 1) {
      setTabsToBeDeleted(pageIndex);
    }
  };

  const handleMovePagePositionUp = (index: number) => {
    if (fields.length > 1) {
      if (index > 0) {
        move(index, index - 1);
        setTabValue(index - 1);
      }
    }
  };

  const handleMovePagePositionDown = (index: number) => {
    if (fields.length > 1) {
      if (index < fields.length - 1) {
        move(index, index + 1);
        setTabValue(index + 1);
      }
    }
  };

  const handleCancelClick = () => {
    history.push('/formbuilding/forms');
  };
  const hasTranslations = getValues(`hasTranslations`);

  return (
    <Container>
      <ExistingTranslationsNotice
        showDialog={hasTranslations}
        noticeText={`This form has existing translations. Editing the form title or a page title will cause those translations to be lost.`}
      />
      <NavigatePrompt
        when={isDirty}
        confirmationText={'Are you sure you want to discard changes to this form?'}
        negativeChoice={'No, keep building'}
      />
      <FormProvider<FormSchema> {...formMethods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={0} className={classes.outerGrid}>
            <Grid item xs={2}>
              <Box display="flex" flexDirection="column" paddingTop="40px">
                <Typography variant="h5" component="h1" className={classes.headerText}>
                  {headerText}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={10} className={classes.surface}>
              <MetadataEdit
                formVersion={currentValues.metadata.version}
                editorMode={editorMode}
                classifications={classifications}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.outerGrid}>
            <Grid item xs={2}>
              <Tabs orientation="vertical" value={tabValue} onChange={handleChange}>
                {fields.map((item, index) => (
                  <FormsTab
                    onRemove={handleRemovePage}
                    onMoveUp={handleMovePagePositionUp}
                    onMoveDown={handleMovePagePositionDown}
                    index={index}
                    title={item.title}
                    key={item.id}
                    label={item.title}
                    {...a11yProps(index)}
                    isLastTab={fields.length === 1}
                    blockVersions={blockVersions}
                    selected={tabValue === index}
                  ></FormsTab>
                ))}
              </Tabs>
              <Button
                startIcon={<AddCircleIcon />}
                aria-label="Add a page to the form"
                onClick={handleCreateNewPage}
                color="primary"
              >
                Add Page
              </Button>
            </Grid>
            <Grid item xs={10} className={classes.surface}>
              <div>
                {fields.map((item, index) => {
                  return (
                    <div key={item.id}>
                      <TabPanel value={tabValue} index={index}>
                        <PageEdit
                          index={index}
                          buildingBlocksData={blockVersions}
                          refreshBlocks={refreshBlocks}
                          isRefreshingBlocks={isRefreshingBlocks}
                        />
                      </TabPanel>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0} alignItems="center" className={classes.outerGrid}>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} className={classes.surface}>
              <Box display="flex" flexDirection="row" justifyContent="flex-end" height="100%">
                <Box display="flex" flexDirection="row" justifyContent="center" marginRight="auto">
                  <StyledButton onClick={generatePreview}>Preview</StyledButton>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Box>
                    <StyledButton onClick={handleCancelClick}>Cancel</StyledButton>
                  </Box>
                  <Box className={classes.saveBtn}>
                    <StyledButton type="submit" disabled={isSubmitting}>
                      Save and Exit
                    </StyledButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Container>
  );
};
