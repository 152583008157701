import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback } from 'react';
import {
  BuildingBlockVersionData,
  Form,
  FormPreview,
  GetFormResponse,
} from './FormBuildingInterfaces';
import { EditorMode } from './FormEdit/FormEdit';

const blankBuildingBlock: BuildingBlockVersionData = {
  key: '',
  versions: [],
};

type GetFormRequest = {
  key: string;
  version?: string;
};

export const useGetForm = (request: GetFormRequest) => {
  return useQuery({
    queryKey: ['formbuilder-form', request],
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<GetFormResponse>(
        `/internalapi/FormBuilding/forms/${request.key}${
          request.version ? `/${request.version}` : ''
        }`,
        { signal },
      );
      return data;
    },
  });
};

export const useGetBuildingBlocks = () => {
  return useQuery({
    queryKey: ['formbuilder-blocks'],
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<BuildingBlockVersionData[]>(
        '/internalapi/FormBuilding/forms/buildingblocks',
        { signal },
      );

      if (!data.find((x) => x.key === '')) {
        data.unshift(blankBuildingBlock);
      }

      return data;
    },
  });
};

type SubmitOptions = {
  editorMode: EditorMode;
  key: string;
  version?: number;
};

// if new Form, post without key or version
// if updating version, post with key and version
// if adding new version, post with only key
const generatePostUrl = (options: SubmitOptions) => {
  switch (options.editorMode) {
    case 'new':
      return `/internalapi/FormBuilding/forms`;
    case 'edit':
      return `/internalapi/FormBuilding/forms/${options.key}/${options.version}`;
    case 'newversion':
      return `/internalapi/FormBuilding/forms/${options.key}`;
  }
};

export const useSaveForm = (options: SubmitOptions) => {
  const mutation = useMutation({
    mutationFn: async (form: Form) => {
      try {
        const response = await axios(generatePostUrl(options), {
          method: options.editorMode === 'edit' ? 'PUT' : 'POST',
          data: form,
          validateStatus: (status) => status === 200 || status === 409,
        });
        return response.status;
      } catch {
        return false;
      }
    },
  });

  return useCallback(async (form: Form) => mutation.mutateAsync(form), [mutation]);
};

export const useSaveFormPreview = () => {
  const mutation = useMutation({
    mutationFn: async (formPreview: FormPreview) => {
      try {
        const response = await axios.post(
          `/internalapi/FormBuilding/forms/generatepreview`,
          formPreview,
        );
        return response.status === 200;
      } catch {
        return false;
      }
    },
  });

  return useCallback(
    async (formPreview: FormPreview) => mutation.mutateAsync(formPreview),
    [mutation],
  );
};

export const useGetDocumentClassifications = () => {
  return useQuery({
    queryKey: ['formbuilder-classifications'],
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<string[]>(
        '/internalapi/FormBuilding/forms/documentclassifications',
        { signal },
      );
      return data;
    },
  });
};
