import { logReactError } from '@airelogic/common';
import { ErrorTemplate } from '@airelogic/form-management/common';
import { Component, ErrorInfo } from 'react';

interface IState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    logReactError(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorTemplate
          header="Oops! Something went wrong."
          message="We've been notified and will fix it ASAP."
        />
      );
    }
    return this.props.children;
  }
}
