import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback } from 'react';
import { ReferenceDataFields } from '../Types';

export type Options = {
  isNew: boolean;
};

export const useSubmit = ({ isNew }: Options) => {
  const mutation = useMutation({
    mutationFn: async (data: ReferenceDataFields) => {
      try {
        const response = await axios({
          url: `/internalapi/formbuilding/referencedata/${data.key}${data.version ? `/${data.version}` : ''}`,
          data: data.referenceData,
          method: isNew ? 'POST' : 'PUT',
          validateStatus: (status) => status === 200 || status === 409,
        });
        return response.status;
      } catch {
        return false;
      }
    },
  });

  return useCallback(async (data: ReferenceDataFields) => mutation.mutateAsync(data), [mutation]);
};
