import { AppBar, Box, Container, IconButton, Theme, Toolbar, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
//import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

const useStyles = makeStyles()((theme: Theme) => ({
  footerBar: {
    top: 'auto',
    bottom: 0,
    background: theme.palette.info.main,
  },
  toolBar: {
    minHeight: 40,
    flexGrow: 1,
  },
  toolbarButton: {
    color: 'white',
  },
  footerBarTypography: {
    fontSize: 'small',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  footerBarCopyrightTypography: {
    fontFamily: 'Hind',
    fontSize: 'small',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: theme.palette.info.light,
  },
}));

const appVersion = process.env.NX_APP_VERSION;

const FooterBar = () => {
  const { classes } = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <AppBar position="sticky" className={classes.footerBar} component="footer">
      <Container>
        <Toolbar className={classes.toolBar}>
          <Box marginRight="auto">
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              size="medium"
              className={classes.toolbarButton}
            >
              {/* <ChatBubbleOutlineIcon />
              <Typography
                component="h5"
                color="inherit"
                display="inline"
                className={classes.footerBarTypography}
              >
                Feedback
              </Typography> */}
            </IconButton>
          </Box>
          <Box marginLeft="auto" flexWrap="wrap">
            <Typography
              component="p"
              color="inherit"
              display="inline"
              className={classes.footerBarCopyrightTypography}
            >
              © {currentYear} Aire Innovate Limited. All rights reserved. Build: {appVersion}
            </Typography>
            {/* <Typography
              component="h5"
              color="inherit"
              display="inline"
              className={classes.footerBarTypography}
            >
              Privacy Policy
            </Typography>
            <Typography
              component="h5"
              color="inherit"
              display="inline"
              className={classes.footerBarTypography}
            >
              Terms of Use
            </Typography> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default FooterBar;
