import {
  SelectInput,
  SingleCheckbox,
  TextInput,
  When,
} from '@airelogic/form-management/components';
import { Grid } from '@mui/material';
import { FieldErrors, useFormContext, useFormState } from 'react-hook-form';
import { Settings, Styles } from '../../../Controls/SelectDefaults';
import { FormValues } from '../../../Form';

export const styleOptions: {
  value: Styles;
  label: string;
}[] = [
  {
    value: 'default',
    label: 'Default',
  },
  {
    value: 'buttons',
    label: 'Display as buttons',
  },
  {
    value: 'buttons-full-width',
    label: 'Display as full width buttons',
  },
];

interface Props {
  fieldIndex: number;
  supportsMultiSelect: boolean;
}

const DefaultSelectOptions = ({ fieldIndex, supportsMultiSelect }: Props) => {
  const { control, register } = useFormContext<FormValues>();
  const { errors } = useFormState({ control });

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]?.additionalSettings as FieldErrors<Settings>;
  };

  return (
    <>
      <Grid item xs={12}>
        <SelectInput
          label={'Style'}
          error={!!getErrors()?.style}
          helperText={getErrors()?.style?.message}
          {...register(`controlSettings.${fieldIndex}.additionalSettings.style`)}
        >
          {styleOptions.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </SelectInput>
      </Grid>
      <When condition={supportsMultiSelect}>
        <Grid item xs={12}>
          <SingleCheckbox
            label="As a multiselect control"
            control={control}
            name={`controlSettings.${fieldIndex}.additionalSettings.multiSelect`}
          />
        </Grid>
      </When>
      <Grid item xs={12}>
        <SingleCheckbox
          label="With 'other' option"
          control={control}
          name={`controlSettings.${fieldIndex}.additionalSettings.withOther`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Choice filter XPath"
          {...register(`controlSettings.${fieldIndex}.additionalSettings.choiceFilterXPath`)}
          error={getErrors()?.choiceFilterXPath !== undefined}
          helperText={getErrors()?.choiceFilterXPath?.message}
        />
      </Grid>
    </>
  );
};

export default DefaultSelectOptions;
