import { logReactError } from '@airelogic/common';
import { InvalidXPathError } from '@airelogic/xpath';
import { Component, ErrorInfo } from 'react';
import PreviewErrorPage from './PreviewErrorPage';

interface IState {
  error?: Error;
}

export default class PreviewErrorBoundary extends Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ error: error });

    if (error instanceof InvalidXPathError === false) {
      logReactError(error, info);
    }
  }

  render() {
    if (this.state.error) {
      return <PreviewErrorPage message={this.state.error.message} />;
    }
    return this.props.children;
  }
}
