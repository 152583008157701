import { useCallback, useEffect, useState } from 'react';
import { useControlSettingsContext } from '../ControlSettingsContext';
import { ClipboardAction } from '../StateManagement/Reducers/selectionsReducer';
import { selectCanAddControl, selectClipboard } from '../StateManagement/Selectors/ControlSelector';
import { selectSelectedComponent } from '../StateManagement/Selectors/LayoutSelectionsSelector';
import { useAppDispatch, useAppSelector } from '../StateManagement/hooks';
import { pasteControl, updateClipboard } from '../StateManagement/layoutSlice';

export const useClipboard = () => {
  const [canPaste, setCanPaste] = useState(false);
  const [canCutCopy, setCanCutCopy] = useState(false);

  const canAddControl = useAppSelector(selectCanAddControl) === true;
  const clipboard = useAppSelector(selectClipboard);
  const selectedComponent = useAppSelector(selectSelectedComponent);
  const controlSettings = useControlSettingsContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (canAddControl && clipboard) {
      if (clipboard.action === 'cut' && !clipboard.cutControlPasted) {
        setCanPaste(true);
      } else {
        setCanPaste(false);
      }
      if (clipboard.action === 'copy') setCanPaste(true);
    } else {
      setCanPaste(false);
    }
  }, [canAddControl, clipboard]);

  useEffect(() => {
    selectedComponent?.type === 'control' ? setCanCutCopy(true) : setCanCutCopy(false);
  }, [selectedComponent]);

  const updateReduxClipboard = useCallback(
    (action: ClipboardAction) => {
      clearNativeClipboard();
      if (selectedComponent !== undefined) {
        const originalControlSettings = controlSettings.find((c) => c.id === selectedComponent.id);
        const deepCopy = JSON.parse(JSON.stringify(originalControlSettings));

        dispatch(
          updateClipboard({
            action: action,
            originControlSettings: deepCopy,
            originControlId: selectedComponent?.id,
          }),
        );
      }
    },
    [selectedComponent, controlSettings, dispatch],
  );

  const handlePaste = useCallback(() => {
    if (clipboard && canPaste) {
      dispatch(pasteControl({ clipboard: clipboard }));
    }
  }, [clipboard, canPaste, dispatch]);

  const clearNativeClipboard = async () => {
    try {
      await navigator.clipboard.writeText('');
    } catch (error) {
      /* empty */
    }
  };

  return { canPaste, canCutCopy, updateReduxClipboard, handlePaste };
};
