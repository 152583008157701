import { NavigatePrompt, StyledButton } from '@airelogic/form-management/components';
import { Box, Container, Grid } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { getDuplicatedReferenceData } from '../ReferenceData.Service';
import ReferenceDataPageEdit from '../ReferenceDataPageEdit/ReferenceDataPageEdit';
import { ReferenceDataFields } from '../Types';
import { useStyles } from './ReferenceDataFormEdit.styles';
import { useSubmit } from './Service';

interface Props {
  currentValues: ReferenceDataFields;
  isNew: boolean;
}

const ReferenceDataFormEdit = ({ currentValues, isNew }: Props) => {
  const { classes } = useStyles();
  const history = useHistory();
  const formMethods = useForm<ReferenceDataFields>({
    defaultValues: currentValues,
    shouldUnregister: false,
  });

  const {
    handleSubmit,
    setError,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
    reset,
  } = formMethods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      history.push('/formbuilding/referencedata');
    }
  }, [history, isSubmitSuccessful]);

  const submit = useSubmit({
    isNew: isNew,
  });

  const validateReferenceData = (formInput: ReferenceDataFields): boolean => {
    const invalidData = getDuplicatedReferenceData(formInput);
    if (invalidData.length > 0) {
      invalidData.forEach((b) => {
        setError(`referenceData.${b.index}.code`, {
          type: 'manual',
          message: 'This code is used more than once',
        });
      });
      return false;
    }
    return true;
  };

  const onSubmit = async (data: ReferenceDataFields) => {
    if (!validateReferenceData(data)) {
      return;
    }

    const response = await submit(data);

    if (response === 409) {
      setError('key', { message: 'Value is already in use' });
    }

    if (response === 200) {
      reset(undefined, { keepValues: true, keepDirty: false });
    }
  };

  const onCancelClicked = () => {
    history.push('/formbuilding/referencedata');
  };

  return (
    <Container>
      <NavigatePrompt
        when={isDirty}
        confirmationText={'Are you sure you want to discard changes?'}
        negativeChoice={'No, keep editing'}
      />
      <FormProvider<ReferenceDataFields> {...formMethods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)} className={classes.root}>
          <Grid container spacing={0} className={classes.outerGrid}>
            <Grid item xs={12} className={classes.itemPanel}>
              <ReferenceDataPageEdit isNew={isNew} />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" spacing={0} alignItems="center">
            <Grid item xs={12} className={classes.surface}>
              <Box display="flex" flexDirection="row" justifyContent="flex-end" height="100%">
                <Box display="flex" flexDirection="row">
                  <StyledButton onClick={onCancelClicked}>Cancel</StyledButton>
                  <StyledButton type="submit" disabled={isSubmitting}>
                    Save and Exit
                  </StyledButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Container>
  );
};
export default ReferenceDataFormEdit;
