import { SvgIconComponent } from '@mui/icons-material';
import { TextField, TextFieldProps } from '@mui/material';
import * as React from 'react';
import InputTooltip from '../Tooltips/InputTooltip';

interface Props
  extends Omit<TextFieldProps, 'variant' | 'ref' | 'inputRef' | 'size' | 'margin' | 'InputProps'> {
  tooltip?: NonNullable<React.ReactNode>;
  adornmentIcon?: React.ReactElement<SvgIconComponent>;
  readOnly?: boolean;
}

const SelectInput = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLInputElement>): React.ReactElement => {
    const { adornmentIcon, tooltip, readOnly = false, helperText, ...rest } = props;

    return (
      <TextField
        id={props.id ?? props.name}
        fullWidth
        margin="dense"
        {...rest}
        inputRef={ref}
        variant="outlined"
        size="small"
        select
        SelectProps={{
          native: true,
        }}
        InputProps={{
          startAdornment:
            tooltip && adornmentIcon ? (
              <InputTooltip title={tooltip}>{adornmentIcon}</InputTooltip>
            ) : tooltip && !adornmentIcon ? (
              <InputTooltip title={tooltip} />
            ) : undefined,
          readOnly: readOnly,
        }}
        InputLabelProps={{ shrink: true }}
      />
    );
  },
);

export default SelectInput;
