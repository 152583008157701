import { IconButton } from '@airelogic/form-management/components';
import { Grid, SvgIcon, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CopyIcon, CutIcon, PasteIcon } from '../Assets/index';
import { useClipboard } from './Clipboard.Hook';

const useStyles = makeStyles()((theme: Theme) => ({
  clipboardContainer: {
    padding: theme.spacing(1),
    backgroundColor: '#EDF0F2',
    width: '100%',
  },

  clipboardButtonDisabled: {
    filter: 'grayscale(100%)',
  },
}));

const Clipboard = () => {
  const { classes, cx } = useStyles();
  const { canPaste, canCutCopy, updateReduxClipboard, handlePaste } = useClipboard();

  return (
    <Grid className={classes.clipboardContainer}>
      <Grid container direction="row">
        <Grid item xs={4} display="flex" justifyContent="center">
          <IconButton
            tooltipText="Cut"
            className={cx({ [classes.clipboardButtonDisabled]: !canCutCopy })}
            onClick={() => updateReduxClipboard('cut')}
            disabled={!canCutCopy}
          >
            <SvgIcon component={CutIcon} />
          </IconButton>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center">
          <IconButton
            tooltipText="Copy"
            className={cx({ [classes.clipboardButtonDisabled]: !canCutCopy })}
            onClick={() => updateReduxClipboard('copy')}
            disabled={!canCutCopy}
          >
            <SvgIcon component={CopyIcon} />
          </IconButton>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center">
          <IconButton
            tooltipText="Paste"
            className={cx({ [classes.clipboardButtonDisabled]: !canPaste })}
            onClick={handlePaste}
            disabled={!canPaste}
          >
            <SvgIcon component={PasteIcon} />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Clipboard;
